import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Switch from './Switch';

const MicPermissionSwitch = () => {
  const [isGranted, setIsGranted] = useState(null);

  const onPermissionStatusChanged = (status) => {
    setIsGranted(status);
  };

  useEffect(() => {
    if (isMobile) {
      // WebView가 로드되고 나서 권한 상태를 확인할 수 있도록 Android에서 전달된 상태 처리
      window.onPermissionStatusChanged = onPermissionStatusChanged;

      const checkPermission = async () => {
        if (window.Android && window.Android.isMicrophonePermissionGranted) {
          const granted = window.Android.isMicrophonePermissionGranted();
          setIsGranted(granted);
        } else {
          console.error('Android interface not available');
        }
      };

      checkPermission();
    }
  }, []);

  const handleClickSwitch = () => {
    if (window.Android && window.Android.openAppSettings) {
      window.Android.openAppSettings();
    } else {
      console.error('Android interface not available');
    }
  };

  return (
    <div className="mic_permission">
      <p className="title">
        마이크
        <br />
        권한허용
      </p>
      <Switch isOn={!!isGranted} onClick={handleClickSwitch} />
    </div>
  );
};

export default MicPermissionSwitch;
