import { getAsyncActionTypes } from 'src/stores/utils';
import apis from 'src/apis';
import { getSpecialLetter } from 'src/utils/learning';

const prefix = 'quiz';

export const [GetQuizQuestionsActions] = ['GET_QUIZ_QUESTIONS'].map(
  (actionName) => getAsyncActionTypes(prefix, actionName)
);

const QuizActions = {
  RESET_QUIZ: `${prefix}/RESET_QUIZ`,
  SET_IDX: `${prefix}/SET_IDX`,
  ADD_ANSWER: `${prefix}/ADD_ANSWER`,
  ADD_TYPING_ANSWER: `${prefix}/ADD_TYPING_ANSWER`,
  NEXT_QUESTION: `${prefix}/NEXT_QUESTION`,
  APPEND_ANSWER: `${prefix}/APPEND_ANSWER`,
  REMOVE_LAST_ANSWER: `${prefix}/REMOVE_LAST_ANSWER`,
};

const initialState = {
  idx: -1,
  questions: [],
  answers: [],
  showResult: false,
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetQuizQuestionsActions.SUCCESS:
      return { ...state, ...action.payload };
    case QuizActions.SET_IDX:
      return { ...state, idx: action.idx };
    case QuizActions.ADD_ANSWER:
      const { answer } = action.payload;
      const newAnswers = state.answers.concat(answer);
      return { ...state, answers: newAnswers };
    case QuizActions.ADD_TYPING_ANSWER: {
      const { answers, idx, questions } = state;
      const { answer } = action.payload;
      const newAnswers = answers.slice(0, answers.length - 1);
      newAnswers.push(answer);

      if (idx === questions.length - 1) {
        return { ...state, answers: newAnswers, showResult: true };
      } else {
        return { ...state, answers: newAnswers, idx: idx + 1 };
      }
    }
    case QuizActions.NEXT_QUESTION: {
      const { questions, idx } = state;
      if (idx === questions.length - 1) {
        return { ...state, showResult: true };
      } else {
        return { ...state, idx: idx + 1 };
      }
    }
    case QuizActions.APPEND_ANSWER: {
      const { answers, questions, idx } = state;
      const question = questions[idx].eng;
      const answer = answers[idx] || '';
      // const type = questions[idx].ss_exercise.exercise_type;
      const type =
        questions[idx]?.type === 'speaking' ? 'sentence' : questions[idx]?.type;
      const { letter } = action.payload;

      if (type === 'word') {
        const questionLetter = question.replace(/ /g, '');
        const answerLetter = answer.replace(/ /g, '');
        if (answerLetter.length < questionLetter.length) {
          const nextQuestionLetter = question[answer.length + 1];
          const newAnswers = answers.slice(0, idx);
          newAnswers.push(
            `${answer}${letter}${getSpecialLetter(nextQuestionLetter)}`
          );
          return {
            ...state,
            answers: newAnswers,
          };
        }
      } else {
        const questionArr = question.split(' ').filter((item) => item !== '');
        const answerArr = answer.split(' ');
        // question이 한 단어로된 문장일 경우
        if (questionArr.length === 1) {
          const newAnswers = answers.slice(0, idx);
          newAnswers.push(`${answer}${letter}`);
          return {
            ...state,
            answers: newAnswers,
          };
        } else if (answerArr.length < questionArr.length) {
          const newAnswers = answers.slice(0, idx);
          if (answer.length > 0) {
            newAnswers.push(`${answer} ${letter}`);
          } else {
            newAnswers.push(`${answer}${letter}`);
          }

          return {
            ...state,
            answers: newAnswers,
          };
        }
      }
      return state;
    }
    case QuizActions.REMOVE_LAST_ANSWER: {
      const { questions, answers, idx } = state;
      const type =
        questions[idx]?.type === 'speaking' ? 'sentence' : questions[idx]?.type;

      const answer = answers[idx];
      const answerLen = answer.length;
      const newAnswers = answers.slice(0, idx);

      if (type === 'word') {
        newAnswers.push(answer.substring(0, answerLen - 1));
        return { ...state, answers: newAnswers };
      } else {
        const answerArr = answer.split(' ');
        if (answerArr.length > 0) {
          const lastWordLen = answerArr[answerArr.length - 1].length;
          newAnswers.push(answer.substring(0, answerLen - lastWordLen - 1));
          return { ...state, answers: newAnswers };
        }
      }
    }
    default:
      return state;
  }
};

export function getQuizQuestions(payload) {
  return {
    type: GetQuizQuestionsActions,
    request: apis.quiz.getQuizQuestions(payload),
  };
}

export function resetQuiz() {
  return {
    type: QuizActions.RESET_QUIZ,
  };
}

export function setIdx(idx) {
  return {
    type: QuizActions.SET_IDX,
    idx,
  };
}

export function addAnswer(payload) {
  return {
    type: QuizActions.ADD_ANSWER,
    payload,
  };
}

export function addTypingAnswer(payload) {
  return {
    type: QuizActions.ADD_TYPING_ANSWER,
    payload,
  };
}

export function nextQuestion() {
  return {
    type: QuizActions.NEXT_QUESTION,
  };
}

export function appendAnswer(payload) {
  return {
    type: QuizActions.APPEND_ANSWER,
    payload,
  };
}

export function removeLastAnswer() {
  return {
    type: QuizActions.REMOVE_LAST_ANSWER,
  };
}

export default quizReducer;
