const Switch = ({ isOn, onClick }) => {
  return (
    <span className="mic_permission_switch">
      <span className={`circle ${isOn ? 'on' : ''}`}>
        <input type="checkbox" checked="" onClick={onClick} />
        <span className="thumb"></span>
      </span>
      <span class="track"></span>
    </span>
  );
};

export default Switch;
